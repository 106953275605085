import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finance"},[_c(VTabs,{staticClass:"finance__container",attrs:{"color":"var(--bg-dark)","hide-slider":""}},[_c(VTab,{staticClass:"finance__tab",attrs:{"active-class":"finance__tab-active"}},[_vm._v("Реестр")]),_c(VTab,{staticClass:"finance__tab",attrs:{"active-class":"finance__tab-active"}},[_vm._v("Запросы")]),_c(VTabItem,{staticStyle:{"margin-top":"-25px"}},[_c('FinanceInvoicesList')],1),_c(VTabItem,[_c(VBtn,{staticClass:"finance__req-btn",attrs:{"color":"#EF8159","outlined":""},on:{"click":function($event){_vm.showDocumentRequestForm = true}}},[_vm._v(" Заказать документ ")]),_c(VDialog,{attrs:{"width":"40%","height":"60%","content-class":"finance__request","transition":"dialog-bottom-transition"},on:{"click:outside":function($event){_vm.showDocumentRequestForm = false}},model:{value:(_vm.showDocumentRequestForm),callback:function ($$v) {_vm.showDocumentRequestForm=$$v},expression:"showDocumentRequestForm"}},[(_vm.showDocumentRequestForm == true)?_c('FinanceDocumentRequestForm',{staticClass:"finance__request-form",on:{"close":function($event){_vm.showDocumentRequestForm = false}}}):_vm._e()],1),_c('FinanceDocumentList',{staticClass:"finance__request-list"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }